import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Navigation from "sections/Navigation"
import BannerClassic from "sections/Banner-classic"
import FeaturesClassic from "sections/Features-classic"
import Footer from "sections/Footer-classic"

import { GlobalStyle } from "sections/app.style"
import theme from "theme/classic/themeStyles"

import AppScreenImage from "assets/images/app/app-screen/todo.png";

import Icon1 from "assets/images/app/app-feature/1.svg";
import Icon2 from "assets/images/app/app-feature/2.svg";
import Icon3 from "assets/images/app/app-feature/6.svg";
import Icon4 from "assets/images/app/app-feature/5.svg";
import Icon5 from "assets/images/app/app-feature/4.svg";

class FeatureInfo {
  constructor(title, detail, icon) {
    this.title = title;
    this.detail = detail;
    this.icon = icon;
  }
}

const TodoAnythingPage = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Next To Do" />
      <Navigation pageName="Todo" />
      <BannerClassic 
        title="organize life" appName="NextToDo" 
        detail="plan your day, stay organized"
        appScreenImage={AppScreenImage}
        link_web = "/NextToDo#appFeatures"
        link_apple = "https://apps.apple.com/us/app/next-to-do-to-do-list-task/id1552323822"
      />
      <div id="appFeatures">
        <FeaturesClassic 
          appName="Next To Do"
          itemGroup={[
              new FeatureInfo("Cloud syncing", "Automatically sync with iCloud",Icon1),
              new FeatureInfo("Multiple boxes", "Organize with boxes", Icon2),
              new FeatureInfo("Customize", "Filter, sort and style", Icon3),
              new FeatureInfo("Statistics", "Task statistics", Icon4),
              new FeatureInfo("Surprise !", "Let’s see what’s behind the UI", Icon5)
            ]} 
        />
      </div>
      <Footer privacyLink="/Privacy/privacy_en" termsLink="/Terms/terms_en" />
    </Layout>
  </ThemeProvider>
)
export default TodoAnythingPage